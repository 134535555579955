import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Accordion from "@ui/accordion";
import { Container, Row, Col } from "@ui/wrapper";
import {
  SectionTitleType,
  ItemType,
  VideoButtonType,
  ImageType,
} from "@utils/types";
import { SectionWrap } from "./style";
import Button from "@ui/button";

const FaqArea = ({ data, space }) => {
  return (
    <SectionWrap $space={space} id={data.section}>
      <Container>
        {data?.section_title && (
          <SectionTitle mb="44px" {...data.section_title} />
        )}

        <Row>
          <Col lg={12}>
            {data?.items && (
              <Accordion disableDefaultActive layout={2} data={data.items} />
            )}
            {!!data.path && (
              <Button
                path={data.path}
                variant="texted"
                fontWeight={400}
                icondistance="8px"
              >
                Request a quote today
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

FaqArea.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    video_button: PropTypes.shape(VideoButtonType),
  }),
  space: PropTypes.oneOf([1, 2, 3]),
};

FaqArea.defaultProps = {
  space: 1,
};

export default FaqArea;
