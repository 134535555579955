import styled, { css, device, themeGet } from "@styled";
import orangewaveBg from "@data/images/bg/orangewaveBg.webp";
import rightArrow from "@data/images/icons/rightArrow.png";

export const HowweworkWrapper = styled.section`
  padding: 150px 0 90px;
  background: url(${orangewaveBg});
  background-position: Center top;
  background-size: cover;
  background-repeat: no-repeat;
  ${(props) =>
    props.layout === 1 &&
    css`
      padding: 150px 0 90px;
      background: url(${orangewaveBg});
      background-position: Center top;
      background-size: cover;
      background-color: #f7f7f7;
      background-repeat: no-repeat;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      padding: 110px 0;
      background: linear-gradient(
        320deg,
        ${themeGet("colors.primaryDark")},
        ${themeGet("colors.primary")}
      );
    `}
  ${(props) =>
    props.layout === 3 &&
    css`
      padding: 150px 0 90px; 
      background: url(${orangewaveBg});
      background-position: Center top;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: -100px;
    `}
    .section-title {
    h2 {
      border-color: #fff;
    }
  }
  ${(props) =>
    props.layout === 4 &&
    css`
      padding: 110px 0;
      background: linear-gradient( 320deg, #551682, #1a0853);
      .section-title {
        h2 {
          color: #f04c19;
          border-color: #f94f19;
        }
    `}
`;
export const HowweworkInner = styled.div`
  .workstepcol {
    z-index: 1;
    &::before {
      content: "";
      left: calc(-50% + 28px);
      right: -50%;
      top: 29.5px;
      position: absolute;
      z-index: 0;
      border-bottom: 1px dashed #fff;
    }
    &:first-child,
    &:last-child {
      &:before {
        content: none;
      }
    }
  }
`;
export const WorkStepWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
  position: relative;
  ${device.medium} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const WorkStepWrapInner = styled.div`
  text-align: center;
  position: relative;
  &::before {
    content: none;
    ${device.medium} {
      content: "";
      top: 29.5px;
      position: absolute;
      z-index: 0;
      border-bottom: 1px dashed #fff;
    }
  }
  &:not(:last-child) {
    &::after {
      content: none;
      ${device.medium} {
        content: "";
        background-image: url(${rightArrow});
        position: absolute;
        top: 22px;
        right: -20px;
        width: 17px;
        height: 17px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &:nth-child(1) {
    &::before {
      left: 50%;
      right: 0;
    }
  }
  &:nth-child(2) {
    &::before {
      left: -38px;
      right: -38px;
    }
  }
  &:nth-child(3) {
    &::before {
      left: 0;
      right: 50%;
    }
  }
`;
export const WorkStepiconWrap = styled.div`
  position: relative;
`;
export const WorkStepicon = styled.span`
  color: #fff;
  font-size: 27px;
  font-weight: bold;
  background: #f16f37;
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px dashed #fff;
  margin-bottom: 50px;
`;
export const WorkStepTextWrap = styled.div``;
