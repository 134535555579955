import styled, { device, css } from "@styled";
import quoteBG from "@data/images/bg/quote-bg.png";

export const SectionWrap = styled.div`
  background: #f8f8f8;
  position: relative;
  ${({ $space }) =>
    $space === 1 &&
    css`
      padding-block-start: 53px;
      padding-block-end: 60px;
      ${device.medium} {
        padding-block-start: 72px;
        padding-block-end: 80px;
      }
      ${device.large} {
        padding-block-start: 89px;
        padding-block-end: 80px;
      }
    `}
  ${({ $space }) =>
    $space === 2 &&
    css`
      padding-block-start: 35px;
      padding-block-end: 60px;
      ${device.medium} {
        padding-block-start: 53px;
        padding-block-end: 75px;
      }
      ${device.large} {
        padding-block-start: 94px;
        padding-block-end: 68px;
      }
    `}
    ${({ $space }) =>
    $space === 3 &&
    css`
        padding:40px 0;
        margin-top:30px;
         ${device.small}{
          margin-top:0;
         }
        ${device.medium}{
          padding:60px 0;
        }
        ${device.large}{ 
          padding:80px 0;
        }
        ${device.xlarge}{ 
          padding:100px 0;
        }
    `}
    &::before {
    content: "";
    position: absolute;
    width: 250px;
    height: 250px;
    opacity: 0.05;
    background: url(${quoteBG});
    background-size: contain;
    background-repeat: no-repeat;
    left: 15px;
    top: 100px;
  }
`;
